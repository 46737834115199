// Header.js
import React from 'react';

const Header = () => {
    return (
        <header style={styles.headerStyle}>
            <h1 style={styles.titleStyle}>Taxonomy NFT Gallery</h1>
            {/* Add more header content here if needed */}
        </header>
    );
};

const styles = {
    headerStyle: {
        background: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '10px 0'
    },
    titleStyle: {
        margin: '0'
    }
};

export default Header;
