// Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer style={styles.footerStyle}>
            <p style={styles.footerTextStyle}>© {new Date().getFullYear()} Kromke Taxonomy NFT Gallery</p>
            {/* Add more footer content here if needed */}
        </footer>
    );
};

const styles = {
    footerStyle: {
        background: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '10px 0',
        width: '100%'
    },
    footerTextStyle: {
        margin: '0'
    }
};


export default Footer;
