// PlantTaxonomy.js
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import Web3 from 'web3';
import contractABI from './contractABI'; // Import the ABI
import Footer from './Footer'; // Make sure the path is correct

// NftCard Component
const NftCard = ({ nft, mintNFT, mintCounts }) => (
  <Col key={nft.id} md={4}>
    <Card className="mb-4">
      <Card.Img variant="top" src={nft.image} alt={nft.name} style={{ maxWidth: '33.33%', height: 'auto' }} />
      {nft.id === 2 && (
        <>
          <img 
            src="https://bafkreihrjwrf5sl2sv6zxqt7z2z2enfdzniimzri3m7tbhrodfpxh6bnoq.ipfs.nftstorage.link/" 
            alt="Close-up of Callicarpa Americana Flowers"
            style={{ width: '20%', height: 'auto' }}
          />
          <img 
            src="https://bafybeigsu2fhltvrtue4zewz4emmo3bonabjoj4xmun2a2b2spedawnam4.ipfs.nftstorage.link/" 
            alt="callicarpa berries"
            style={{ maxWidth: '33.33%', height: 'auto' }}    
          />
        </>  
      )}
      <Card.Body>
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px' }}>
          <Card.Title style={{ color: 'black' }}>{nft.name}</Card.Title>
        </div>
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px' }}>
          <Card.Text style={{ color: 'black' }}>{nft.description}</Card.Text>
        </div>
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px' }}>
          <p style={{ color: 'black' }}>Minted: {mintCounts[nft.image] || 0} times</p>
        </div> 
        <Button variant="primary" onClick={() => mintNFT(nft.metadata)}>
          Purchase NFT
        </Button>
      </Card.Body>
    </Card>
  </Col>
);



// LoadingIndicator Component
const LoadingIndicator = () => (
  <div className="text-center">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
    <p>Loading...</p>
  </div>
);


const contractAddress = '0xbe6Cb2Ea1C78848e6FBE06A152AdF906D5c4706E'; // Your contract address

const NFTs = [
  {
    id: 1,
    name: 'Passiflora incarnata',
    description: `Passion Flower (Passiflora incarnata), known as passionflower, is a vine native to the Americas. It thrives in dense, humid woodlands. Passion Flower has a range of medicinal properties. It's used for its sedative and anxiolytic effects to manage anxiety, insomnia, and neurological disorders. It also eases muscle cramps and hypertension. Rich in flavonoids, glycosides, and amino acids, it offers antibacterial, anti-fungal, and analgesic benefits. Its bioactive molecules like apigenin, harmaline, and quercetin contribute to its health benefits.`,
    image: 'https://bafkreibegirxmtv3txhgz24peo6c6y3dq6vidmd5vubxcuomexyys7wi7e.ipfs.nftstorage.link/',
    metadata: 'https://bafkreidb4wyj5yefvvnkwpbrzpldjmn64n64prbntkluwyb5dx3gepce2i.ipfs.nftstorage.link/',
  },
  {
    id: 2,
    name: 'Callicarpa americana',
    description: `Callicarpa americana, commonly known as American beautyberry, is a deciduous shrub found in the southeastern United States. It is well-known for its striking clusters of purple berries that encircle its branches, which become especially prominent after the plant loses its leaves in the fall. The plant typically grows to between 3 to 6 feet in height and spreads out to about the same width, often with an open, loosely branched form.

The beautyberry is not only prized for its ornamental value but also has a history of usage by indigenous people and herbalists for various medicinal purposes. Traditionally, the leaves of the Callicarpa americana were used as a natural insect repellent. There is anecdotal evidence that fresh leaves were crushed and placed on horse harnesses to deter insects, a practice adopted by early settlers as well.

In terms of medicinal uses, some Native American tribes believed the root and berries had healing properties and used them in the treatment of dysentery and stomachaches. The roots were also used to make a tea that was thought to help with colic and as a diuretic.

Chemically, the beautyberry contains various compounds that have caught the attention of scientists. One such compound is callicarpenal, which has been shown to have mosquito-repellent properties. Research has indicated that extracts from the leaves can be effective in repelling ticks, fire ants, and other insects, although more studies are needed to fully understand its efficacy and potential applications.

Other than its medicinal and repellent uses, the beautyberry plant provides food for wildlife, particularly birds, which are attracted to its fruit during the winter months.

It’s important to note that while there are traditional uses and some supportive research, many of the medicinal claims have not been rigorously tested in clinical trials. As with any medicinal plant, it should be used with caution and preferably under the guidance of a qualified herbalist or medical professional.`,
    image: 'https://bafybeiad5jgis7ymade52pvrnzdeqglpy5dx33vuosa7z2dgb7cvrkgbmu.ipfs.nftstorage.link/',
    metadata: 'https://bafkreidov32gs2xrcfthg7hmk3dnkjb7fvrwy7z67tnogtsazfckev65ve.ipfs.nftstorage.link/',
  },
];

function App() {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mintCounts, setMintCounts] = useState({});

   const updateMintCounts = useCallback(async () => {
    if (!contract) return;
    const newMintCounts = {};
    for (const nft of NFTs) {
      const mintCount = await contract.methods.getMintCount(nft.image).call();
      newMintCounts[nft.image] = mintCount;
    }
    setMintCounts(newMintCounts);
  }, [contract]); 





useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        try {
          setLoading(true);
          const newWeb3 = new Web3(window.ethereum);
          await window.eth_requestAccounts;
          const accounts = await newWeb3.eth.getAccounts();
          setWeb3(newWeb3);
          setAccounts(accounts);

          const newContract = new newWeb3.eth.Contract(contractABI, contractAddress);
          setContract(newContract);
        } catch (error) {
          console.error("Error initializing web3:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    initWeb3();
  }, []);

  useEffect(() => {
    if (contract) {
      updateMintCounts();
    }
  }, [contract, updateMintCounts]); // Add 'contract' as a dependency


  const mintNFT = async (imageUri) => {
  try {
    const mintValue = web3.utils.toWei("0.001", "ether"); // Convert 0.001 ETH to Wei
    await contract.methods.safeMint(accounts[0], imageUri).send({ from: accounts[0], value: mintValue });
    const newMintCount = await contract.methods.getMintCount(imageUri).call();
    const newMintCounts = { ...mintCounts, [imageUri]: newMintCount };
    setMintCounts(newMintCounts);
    console.log("Updated mintCounts:", newMintCounts);
  } catch (error) {
    console.error("Error minting NFT:", error);
  }
};
  // Rest of your App component code...
  // Including rendering of NFTs and other UI elements
  // ...

  const connectToMetaMask = async () => {
    try {
      setLoading(true);
      if (window.ethereum) {
        const newWeb3 = new Web3(window.ethereum);
        await window.eth_requestAccounts;
        const accounts = await newWeb3.eth.getAccounts();
        setWeb3(newWeb3);
        setAccounts(accounts);
      } else {
        console.error('MetaMask not found');
      }
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    } finally {
      setLoading(false);
    }
  };

  const disconnectWallet = () => {
    setWeb3(null);
    setAccounts([]);
  };

  const backgroundUrl = 'https://bafybeihlq6nktyt4ikx34ro4o5nrse2btacxs26kjaby3ouqylgwatkqwm.ipfs.nftstorage.link/';

  return (
    <div>
      <div style={{ backgroundImage: `url(${backgroundUrl})`, backgroundSize: 'repeat', minHeight: '100vh' }}>
        <Container>
          <h1 className="mt-4 mb-4 text-center" style={{ backgroundColor: 'rgba(240, 240, 240, 0.5)', color: 'red' }}>Plant Taxonomy NFT Minting</h1>


        {!web3 && !loading && (
          <div className="text-center" style={{ display: 'inline-flex', backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '15px' }}>
            <p style={{ margin: 'auto' }}>Not connected to Wallet</p>
            <Button variant="primary" onClick={connectToMetaMask}>
              Connect to Wallet
            </Button>
          </div>
        )}

        {web3 && accounts.length > 0 && (
          <div className="text-center" style={{ display: 'inline-flex', backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '15px' }}>
            <p style={{ margin: 'auto' }}>Connected Account: {accounts[0]}</p>
            <Button variant="danger" onClick={disconnectWallet}>
              Disconnect Wallet
            </Button>
          </div>
        )}

        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Row>
  {NFTs.map((nft) => (
    <NftCard key={nft.id} nft={nft} mintNFT={mintNFT} mintCounts={mintCounts} />
  ))}
</Row>

            
          </>
        )}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default App;

