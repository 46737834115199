// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, } from 'react-router-dom'; // Corrected import statement
import { Button, Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import Web3 from 'web3';
import contractABI from './contractABI'; // Import the ABI
import MushroomTaxonomy from './components/MushroomTaxonomy.js';
import PlantTaxonomy from './components/PlantTaxonomy.js';
import Header from './Header'; // Make sure the path is correct
import Footer from './Footer'; // Make sure the path is correct
import './App.css'; // Add this line to import your CSS


// NftCard Component
const NftCard = ({ nft, mintNFT, mintCounts }) => (
  <Col key={nft.id} md={4}>
    <Card className="mb-4">
      <Card.Img variant="top" src={nft.image} alt={nft.name} />
      <Card.Body>
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px' }}>
          <Card.Title style={{ color: 'black', fontSize: '24px' }}>{nft.name}</Card.Title>
        </div>
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px' }}>
          <Card.Text style={{ color: 'black', fontSize: '18px' }}>{nft.description}</Card.Text>
        </div>
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px' }}>
          <p style={{ color: 'black', fontSize: '16px' }}>Minted: {mintCounts[nft.image] || 0} times</p>
        </div>
        <Button variant="primary" onClick={() => mintNFT(nft.metadata)}>
          Purchase NFT
        </Button>
      </Card.Body>
    </Card>
  </Col>
);


// LoadingIndicator Component
const LoadingIndicator = () => (
  <div className="text-center">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
    <p>Loading...</p>
  </div>
);

const contractAddress = '0xbe6Cb2Ea1C78848e6FBE06A152AdF906D5c4706E';
const NFTs = [
    {
      id: 1,
      name: 'BIOd1v3r5ity',
      description: 'Elegance And Diversity Of Plant Life',
      image: 'https://bafybeiduq2kemefo7v7hpx25djy27yxbadi5mf4dhln6eydcm6fzb7oxp4.ipfs.nftstorage.link/',
      metadata: 'https://bafkreiexy33u3rajgzrihfg6lzakjg4di33dxjma4riwnenrw63xcmbi2q.ipfs.nftstorage.link/',
    },
    {
      id: 2,
      name: 'Taxonomy Chart',
      description: 'It includes labels and scientific names, capturing the essence of biodiversity and taxonomy in a detailed and educational manner.',
      image: 'https://bafybeifmlveskjtw44kyz5gujclb2njdyvjr7uknxix6hojhr5rxsugk6y.ipfs.nftstorage.link/',
      metadata: 'https://bafkreidlgse3u7sef26zhdg6s34lvqbfjzbbjfzp64gonuw2u23npwjhoq.ipfs.nftstorage.link/',
    },
    {
      id: 3,
      name: 'D1g1tal MuShr00m',
      description: 'Mushroom Digital Art Space',
      image: 'https://bafybeiccyb3v6lyi5wsli267vuwuamoqtibtfs4juzxsjwh3brjvp4o7fu.ipfs.nftstorage.link/',
      metadata: 'https://bafkreiddkuhpl4h4nwmmlyemuralv6kiowlpy6pzrn4zsqhbemscivvxxu.ipfs.nftstorage.link/',
    },
    {
      id: 4,
      name: 'Sh0wT1m3',
      description: 'Mushrooms growing, showcasing a time-lapse effect. This was made with an animation that started with spores, mycelium and gradually shows the development of the mushrooms, emerging from the ground and growing to full size. The mushrooms are artistically rendered with attention to their shapes, textures, and colors, set against a minimalistic background to keep the focus on the growing process.',
      image: 'https://bafybeiagdvdm3jnrwz4axfto4w6coddxbvirc7wpqjbcbuvhir2ysdnswq.ipfs.nftstorage.link/',
      metadata: 'https://bafkreihhphmj7i6sepwsxvcs3r7x7iwfv3rmrbqowkiiorp2vtjxo427ey.ipfs.nftstorage.link/',
    },
    {
      id: 5,
      name: 'My5tical G4n0d3rma Luc1dum',
      description: 'The design embodies a mystical and enchanting vibe, showcasing the mushroom in a visually stunning manner. This NFT artwork combines the beauty, mystery, and essence of natural healing.',
      image: 'https://bafybeihik6ondjkwb6d4yz6yyh6fe4dnsd3nroa755v2jj6tln4z5o4nhi.ipfs.nftstorage.link/',
      metadata: 'https://bafkreib5huzhbo7jitdbsclt3aajchpqbqjt5g4zri2jyrro24lecszlyy.ipfs.nftstorage.link/',
    },
  ];

function App() {
  const showOnlyMushroomTaxonomy = new URLSearchParams(window.location.search).get('page') === 'mushroomTaxonomy';
  const showOnlyPlantTaxonomy = new URLSearchParams(window.location.search).get('page') === 'PlantTaxonomy';
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mintCounts, setMintCounts] = useState({});

   const updateMintCounts = useCallback(async () => {
    if (!contract) return;
    const newMintCounts = {};
    for (const nft of NFTs) {
      const mintCount = await contract.methods.getMintCount(nft.image).call();
      newMintCounts[nft.image] = mintCount;
    }
    setMintCounts(newMintCounts);
  }, [contract]); 

useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        try {
          setLoading(true);
          const newWeb3 = new Web3(window.ethereum);
          // Correctly request access to account
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const accounts = await newWeb3.eth.getAccounts();
          setWeb3(newWeb3);
          setAccounts(accounts);

          const newContract = new newWeb3.eth.Contract(contractABI, contractAddress);
          setContract(newContract);
        } catch (error) {
          console.error("Error initializing web3:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    initWeb3();
  }, []);


  useEffect(() => {
    if (contract) {
      updateMintCounts();
    }
  }, [contract, updateMintCounts]); // Add 'contract' as a dependency


  // Updated function to mint an NFT with 0.001 ETH value
const mintNFT = async (imageUri) => {
  try {
    const mintValue = web3.utils.toWei("0.001", "ether"); // Convert 0.001 ETH to Wei
    await contract.methods.safeMint(accounts[0], imageUri).send({ from: accounts[0], value: mintValue });
    const newMintCount = await contract.methods.getMintCount(imageUri).call();
    const newMintCounts = { ...mintCounts, [imageUri]: newMintCount };
    setMintCounts(newMintCounts);
    console.log("Updated mintCounts:", newMintCounts);
  } catch (error) {
    console.error("Error minting NFT:", error);
  }
};
  // Rest of your App component code...
  // Including rendering of NFTs and other UI elements
  // ...

  const connectToMetaMask = async () => {
    try {
      setLoading(true);
      if (window.ethereum) {
        const newWeb3 = new Web3(window.ethereum);
        // Correctly request access to account
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await newWeb3.eth.getAccounts();
        setWeb3(newWeb3);
        setAccounts(accounts);
      } else {
        console.error('MetaMask not found');
      }
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    } finally {
      setLoading(false);
    }
  };


  const disconnectWallet = () => {
    setWeb3(null);
    setAccounts([]);
  };

    // Correctly defined background image URL
  const backgroundUrl = 'https://bafybeiew4bnybaxvywykhduzibvuvegb7vh34q7544ap6jiqnorzdokqyy.ipfs.nftstorage.link/';
  const styles = {
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  };
  return (
      <Router>
        <div style={styles.flexContainer}>
          <Header />
          <div className="sidenav">
            {/* Place your links here */}
            <a href="/?page=mushroomTaxonomy" target="_blank" rel="noopener noreferrer">Mushroom Taxonomy NFTs</a>
            <br />
            <a href="/?page=PlantTaxonomy" target="_blank" rel="noopener noreferrer">Plant Taxonomy NFTs</a>
          </div>
        {showOnlyMushroomTaxonomy && <MushroomTaxonomy />}
        {showOnlyPlantTaxonomy && <PlantTaxonomy />}
        {!showOnlyMushroomTaxonomy && !showOnlyPlantTaxonomy && (
          <>
        <div style={{ paddingBottom: '60px', backgroundImage: `url(${backgroundUrl})`, backgroundSize: 'repeat', minHeight: '100vh' }}>              <Container>
                <h1 className="mt-4 mb-4 text-center" style={{ backgroundColor: 'rgba(240, 240, 240, .9)', color: 'red' }}>Discover the Wonders of Nature with NFTs: Explore Plants, Fungi, and More in a Whole New Way</h1>

                {!web3 && !loading && (
                  <div className="text-center" style={{ display: 'inline-flex', backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '15px' }}>
                    <p style={{ margin: 'auto' }}>Not connected to Wallet</p>
                    <Button variant="primary" onClick={connectToMetaMask}>
                      Connect to Wallet
                    </Button>
                  </div>
                )}

                {web3 && accounts.length > 0 && (
                  <div className="text-center" style={{ display: 'inline-flex', backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '15px' }}>
                    <p style={{ margin: 'auto' }}>Connected Account: {accounts[0]}</p>
                    <Button variant="danger" onClick={disconnectWallet}>
                      Disconnect Wallet
                    </Button>
                  </div>
                )}
                
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Row>
                      {NFTs.map((nft) => (
                        <NftCard key={nft.id} nft={nft} mintNFT={mintNFT} mintCounts={mintCounts} />
                      ))}
                    </Row>
                    <a href="/?page=mushroomTaxonomy" target="_blank" rel="noopener noreferrer">Mushroom Taxonomy NFTs</a>
                    <a href="/?page=PlantTaxonomy" target="_blank" rel="noopener noreferrer">Plant Taxonomy NFTs</a>

                  </>
                )}
              </Container>
            </div>
            <Footer />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
